<style scoped>
.regra {
  position: relative;
  border: 2px solid #ddd;
  padding: 12px;
  margin-bottom: 24px;
}

.regra + .regra::before {
  position: absolute;
  text-align: center;
  content: "Ou a regra abaixo deve ser válida";
  top: -22px;
}

.regra-and + .regra-and::before {
  content: "E a regra abaixo também deve válida";
}

.regra-container {
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.regra-dimensao {
  position: relative;
  margin-right: 16px;
}

.regra-dimensao + .regra-dimensao::before {
  position: absolute;
  bottom: 5px;
  left: -12px;
  content: "e";
}

.regra-dimensao label {
  font-size: 12px;
  font-weight: bold;
}

.button-delete-container {
  overflow: hidden;
  display: block;
}


.button-delete-container input {
  width: 50px;
  text-align: right;
  border: 1px solid #999;
}

.button-delete-container button {
  float: right;
}

.button-delete-container input {
  width: 50px;
  text-align: right;
  border: 1px solid #999;
}

.button-delete-container button {
  float: right;
}
</style>

<template>
  <div class="col-1-1">
    <div v-for="(regra, index) in campanha.dimensoes">
      <div v-if="regra.tipoRegra === 'SEGMENTACAO'">
        <div
            v-if="validaRegraByDimensao(regra)"
            v-bind:class="{ regra, 'regra-and': campanha.validarTodasRegras }"
            class="regra"
        >
          <h4>Regra de validação</h4>

          <div class="regra-container">
            <div v-for="dimensao in dimensoes" class="regra-dimensao">
              <label>{{ dimensao.label }}</label>
              <select
                  class="form-control"
                  v-model="regra[`dimensao${dimensao.coluna}`]"
                  v-on:change="clonarRegra(regra)"
                  :disabled="campanha.erpId && campanha.id"
              >
                <option></option>
                <option
                    v-for="itemDimensao in dimensao.dimensoes"
                    :value="itemDimensao.id"
                >
                  {{ itemDimensao.desc }}
                </option>
              </select>
              <div class="message">
                {{ validacao.firstError("campanha.tipo") }}
              </div>
            </div>
          </div>
          <h4>
            Regra de aplicação |
            <small>
              <label>
                <input
                    type="checkbox"
                    v-model="regra.aplicaregra"
                    :value="true"
                    v-on:change="clonarRegra(regra)"
                    :disabled="campanha.erpId && campanha.id"
                />
                Aplicar o desconto nos mesmos itens da regra de validação
              </label>
            </small>
          </h4>
          <div class="regra-container">
            <div v-for="dimensao in dimensoes" class="regra-dimensao">
              <label>{{ dimensao.label }}</label>
              <select
                  class="form-control"
                  v-model="regra[`dimensaoaplicacao${dimensao.coluna}`]"
                  :disabled="!!regra.aplicaregra || (campanha.erpId && campanha.id)"
              >
                <option></option>
                <option
                    v-for="itemDimensao in dimensao.dimensoes"
                    :value="itemDimensao.id"
                >
                  {{ itemDimensao.desc }}
                </option>
              </select>
              <div class="message">
                {{ validacao.firstError("item.tipo") }}
              </div>
            </div>
          </div>
          <div v-if="campanha.validarPorRegra">
            <TipoValidacao
                :item="regra"
                :inputShouldBeVisible="inputShouldBeVisible"
                :validacao="validacao"
                :disabled="campanha.erpId && campanha.id"
            />
          </div>
          <div class="button-delete-container">
            Aplicar o desconto de
            <input
                type="number"
                v-model="regra.percDesc"
                :disabled="campanha.erpId && campanha.id"
            />
            % nos itens quecompõem a regra de aplicação
            <button
                type="button"
                class="button button-error"
                v-on:click="excluirRegra(index)"
                :disabled="campanha.erpId && campanha.id"
            >
              <fa-icon icon="trash-alt" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
        type="button"
        class="button button-primary"
        v-on:click="adicionarRegra"
        :disabled="campanha.erpId && campanha.id"
        v-if="computedShowAdicionarRegra"
    >
      Adicionar Regra
    </button>
  </div>
</template>

<script>
import clone from "just-clone";
import _find from "lodash/find";
import TipoValidacao from "@/views/Campanhas/components/CampanhasComerciais/components/Regra/TipoValidacao.vue";

const initialDimensao = () => ({
  dimensao1: "",
  dimensao2: "",
  dimensao3: "",
  dimensao4: "",
  dimensao5: "",
  dimensao6: "",
  dimensao7: "",
  dimensao8: "",
  dimensao9: "",
  dimensao10: "",
  dimensaoaplicacao1: "",
  dimensaoaplicacao2: "",
  dimensaoaplicacao3: "",
  dimensaoaplicacao4: "",
  dimensaoaplicacao5: "",
  dimensaoaplicacao6: "",
  dimensaoaplicacao7: "",
  dimensaoaplicacao8: "",
  dimensaoaplicacao9: "",
  dimensaoaplicacao10: "",
  percDesc: 0,
  aplicaregra: true,
  qtdeMin: 0,
  qtdeMax: null,
  qtdeMinDistinta: 0,
  qtdeMaxDistinta: null,
  vlMin: 0,
  vlMax: 0,
  percMin: 0,
  percMax: 0,
  tipoRegra: 'SEGMENTACAO'
});

  export default {
    components: {TipoValidacao},
    props: ["campanha", "validacao", "dimensoes", "tiposInputs", "showAdicionarRegraButton"],
    data() {
      return {
        dimensao: initialDimensao()
      }
    },
    computed: {
      computedShowAdicionarRegra () {
        return this.showAdicionarRegraButton;
      }
    },
    methods: {
      validaDimensao(dimensao, coluna) {
        if (dimensao == null || dimensao == "" || !dimensao) return true;
        var dimensoes = clone(this.dimensoes);

        const _dimensao = _find(this.dimensoes, (d) => d.coluna === coluna);

        if (!_dimensao) {
          return false;
        }

        var valida = false;
        _dimensao.dimensoes.forEach((e) => {
          if (e.id === dimensao) {
            valida = true;
          }
        });

        return valida;
      },
      adicionarRegra() {
        this.campanha.dimensoes.push(initialDimensao());
      },
      excluirRegra(index) {
        this.campanha.dimensoes.splice(index, 1);
      },
      clonarRegra(regra) {
        if (regra.aplicaregra) {
          for (let idx = 1; idx <= 10; idx++) {
            regra[`dimensaoaplicacao${idx}`] = regra[`dimensao${idx}`];
          }
        }
      },
      validaRegraByDimensao(regra) {
        if (!this.validaDimensao(regra.dimensao1, 1)) return false;
        if (!this.validaDimensao(regra.dimensao2, 2)) return false;
        if (!this.validaDimensao(regra.dimensao3, 3)) return false;
        if (!this.validaDimensao(regra.dimensao4, 4)) return false;
        if (!this.validaDimensao(regra.dimensao5, 5)) return false;
        if (!this.validaDimensao(regra.dimensao6, 6)) return false;
        if (!this.validaDimensao(regra.dimensao7, 7)) return false;
        if (!this.validaDimensao(regra.dimensao8, 8)) return false;
        if (!this.validaDimensao(regra.dimensao9, 9)) return false;
        if (!this.validaDimensao(regra.dimensao10, 10)) return false;

        if (!this.validaDimensao(regra.dimensaoaplicacao1, 1)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao2, 2)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao3, 3)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao4, 4)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao5, 5)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao6, 6)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao7, 7)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao8, 8)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao9, 9)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao10, 10)) return false;

        return true;
      },
      inputShouldBeVisible(inputName) {
        return (
            this.tiposInputs[this.campanha.validacao] &&
            this.tiposInputs[this.campanha.validacao].indexOf(inputName) >= 0
        );
      },
    }
  }
</script>
