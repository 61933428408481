<style scoped>
.regra {
  position: relative;
  border: 2px solid #ddd;
  padding: 12px;
  margin-bottom: 24px;
}

.regra + .regra::before {
  position: absolute;
  text-align: center;
  content: "Ou a regra abaixo deve ser válida";
  top: -22px;
}

.regra-and + .regra-and::before {
  content: "E a regra abaixo também deve válida";
}

.regra-container {
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.regra-dimensao {
  position: relative;
  margin-right: 16px;
}

.regra-dimensao + .regra-dimensao::before {
  position: absolute;
  bottom: 5px;
  left: -12px;
  content: "e";
}

.regra-dimensao label {
  font-size: 12px;
  font-weight: bold;
}

.button-delete-container {
  overflow: hidden;
  display: block;
}


.button-delete-container input {
  width: 50px;
  text-align: right;
  border: 1px solid #999;
}

.button-delete-container button {
  float: right;
}

.button-delete-container input {
  width: 50px;
  text-align: right;
  border: 1px solid #999;
}

.button-delete-container button {
  float: right;
}
</style>

<template>
  <div class="col-1-1">
    <div v-if="dimensaoCampanha.tipoRegra === 'SEGMENTACAO'">
      <div
          v-if="validaRegraByDimensao(dimensaoCampanha)"
          v-bind:class="{ dimensaoCampanha, 'regra-and': campanha.validarTodasRegras }"
          class="regra"
      >
        <h4>Regra de validação</h4>

        <div class="regra-container">
          <div v-for="dimensao in dimensoes" class="regra-dimensao">
            <label>{{ dimensao.label }}</label>
            <select
                class="form-control"
                v-model="dimensaoCampanha[`dimensao${dimensao.coluna}`]"
                v-on:change="clonarRegra(dimensaoCampanha)"
                :disabled="campanha.erpId && campanha.id"
            >
              <option></option>
              <option
                  v-for="itemDimensao in dimensao.dimensoes"
                  :value="itemDimensao.id"
              >
                {{ itemDimensao.desc }}
              </option>
            </select>
            <div class="message">
              {{ validacao.firstError("campanha.tipo") }}
            </div>
          </div>
        </div>
        <h4>
          Regra de aplicação |
          <small>
            <label>
              <input
                  type="checkbox"
                  v-model="dimensaoCampanha.aplicaregra"
                  :value="true"
                  v-on:change="clonarRegra(dimensaoCampanha)"
                  :disabled="campanha.erpId && campanha.id"
              />
              Aplicar o desconto nos mesmos itens da regra de validação
            </label>
          </small>
        </h4>
        <div class="regra-container">
          <div v-for="dimensao in dimensoes" class="regra-dimensao">
            <label>{{ dimensao.label }}</label>
            <select
                class="form-control"
                v-model="dimensaoCampanha[`dimensaoaplicacao${dimensao.coluna}`]"
                :disabled="!!dimensaoCampanha.aplicaregra || (campanha.erpId && campanha.id)"
            >
              <option></option>
              <option
                  v-for="itemDimensao in dimensao.dimensoes"
                  :value="itemDimensao.id"
              >
                {{ itemDimensao.desc }}
              </option>
            </select>
            <div class="message">
              {{ validacao.firstError("item.tipo") }}
            </div>
          </div>
        </div>
        <div v-if="campanha.validarPorRegra">
          <TipoValidacao
              :item="dimensaoCampanha"
              :inputShouldBeVisible="inputShouldBeVisible"
              :validacao="validacao"
              :disabled="campanha.erpId && campanha.id"
          />
        </div>
        <div class="button-delete-container">
          Aplicar o desconto de
          <input
              type="number"
              v-model="dimensaoCampanha.percDesc"
              :disabled="campanha.erpId && campanha.id"
          />
          % nos itens que compõem a regra de aplicação
          <button
              type="button"
              class="button button-error"
              v-on:click="excluirRegra(index)"
              :disabled="campanha.erpId && campanha.id"
          >
            <fa-icon icon="trash-alt" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clone from "just-clone";
import _find from "lodash/find";
import TipoValidacao from "@/views/Campanhas/components/CampanhasComerciais/components/Regra/TipoValidacao.vue";

  export default {
    components: {TipoValidacao},
    props: ["campanha", "dimensaoCampanha", "validacao", "dimensoes", "tiposInputs", "index"],

    methods: {
      validaDimensao(dimensao, coluna) {
        if (dimensao == null || dimensao == "" || !dimensao) return true;
        var dimensoes = clone(this.dimensoes);

        const _dimensao = _find(this.dimensoes, (d) => d.coluna === coluna);

        if (!_dimensao) {
          return false;
        }

        var valida = false;
        _dimensao.dimensoes.forEach((e) => {
          if (e.id === dimensao) {
            valida = true;
          }
        });

        return valida;
      },
      excluirRegra(index) {
        this.campanha.dimensoes.splice(index, 1);
      },
      clonarRegra(regra) {
        if (regra.aplicaregra) {
          for (let idx = 1; idx <= 10; idx++) {
            regra[`dimensaoaplicacao${idx}`] = regra[`dimensao${idx}`];
          }
        }
      },
      validaRegraByDimensao(regra) {
        if (!this.validaDimensao(regra.dimensao1, 1)) return false;
        if (!this.validaDimensao(regra.dimensao2, 2)) return false;
        if (!this.validaDimensao(regra.dimensao3, 3)) return false;
        if (!this.validaDimensao(regra.dimensao4, 4)) return false;
        if (!this.validaDimensao(regra.dimensao5, 5)) return false;
        if (!this.validaDimensao(regra.dimensao6, 6)) return false;
        if (!this.validaDimensao(regra.dimensao7, 7)) return false;
        if (!this.validaDimensao(regra.dimensao8, 8)) return false;
        if (!this.validaDimensao(regra.dimensao9, 9)) return false;
        if (!this.validaDimensao(regra.dimensao10, 10)) return false;

        if (!this.validaDimensao(regra.dimensaoaplicacao1, 1)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao2, 2)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao3, 3)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao4, 4)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao5, 5)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao6, 6)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao7, 7)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao8, 8)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao9, 9)) return false;
        if (!this.validaDimensao(regra.dimensaoaplicacao10, 10)) return false;

        return true;
      },
      inputShouldBeVisible(inputName) {
        return (
            this.tiposInputs[this.campanha.validacao] &&
            this.tiposInputs[this.campanha.validacao].indexOf(inputName) >= 0
        );
      },
    }
  }
</script>
